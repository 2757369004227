<template>
    <Tooltip :content="content" placement="top" max-width="400" transfer>
        <Icon type="md-help-circle" size="16"></Icon>
    </Tooltip>
</template>

<script>
export default {
    props: {
        content: String,
    },
}
</script>
